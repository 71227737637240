import React, { useEffect } from 'react';

const StripeRedirect = ({ publishableKey, sessionId }) => {
    useEffect(() => {
        const redirectToStripe = async () => {
            // Load the Stripe.js script
            const script = document.createElement('script');
            script.src = 'https://js.stripe.com/v3/';
            script.async = true;
            script.onload = () => {
                // Initialize Stripe and redirect to checkout
                const stripe = window.Stripe(publishableKey);
                stripe.redirectToCheckout({ sessionId });
            };
            document.body.appendChild(script);
        };

        redirectToStripe();
    }, [publishableKey, sessionId]);

    return (
        <div>
            <h5>Redirecting to Stripe...</h5>
        </div>
    );
};

export default StripeRedirect;