import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import swal from "sweetalert";
import Select from "react-select";
import { ageFn } from "./age";

const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [namePar, setNamePar] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailPar, setEmailPar] = useState("");
  const [pays, setPays] = useState([]);
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneCodeSelect, setPhoneCodeSelect] = useState("");
  const [phoneCodeSelectPar, setPhoneCodeSelectPar] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberPar, setPhoneNumberPar] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [age, setAge] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [paysSelected, setPaysSelected] = useState();
  const [ville, setVille] = useState([]);
  const [villeSelected, setVilleSelected] = useState();
  const [errors, setErrors] = useState([]);

  const phoneC = async () => {
    await axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .get(`/api/phoneCode`)
        .then((res) => {
          setPhoneCode(res.data.data);
          console.log(res.data);
        })
        .catch(function (err) {
          console.log(err);
        });
    });
  };
  const loadPays = async () => {
    await axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .get(`/api/pays`)
        .then((response) => {
          const paysArray = [];
          var paysData = response.data;
          if (paysData.success === true) {
            paysData.data.map((itmes) =>
              paysArray.push({
                value: itmes.id,
                label: itmes.nom,
              })
            );
            console.log('valid "pays" , message', paysData.message);
            console.log(paysArray.length);
            setPays(paysArray);
          } else {
            console.log(
              'Error "Ville" file banner.jsx , msg' + paysData.message
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log("on load ville function");
    });
  };
  /* const paysFunction = (e) => {
    setPaysSelected(e);
    loadVille(e.value);
  }; */
  const loadVille = (idPays) => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .get(`/api/ville/${idPays}`)
        .then((response) => {
          const villeArray = [];
          var VilleData = response.data;
          if (VilleData.success === true) {
            VilleData.data.map((itmes) =>
              villeArray.push({
                value: itmes.id,
                label: itmes.nom,
              })
            );
            console.log('valid "Ville" , message', VilleData.message);
            console.log(villeArray.length);
            setVille(villeArray);
          } else {
            console.log(
              'Error "Ville" file banner.jsx , msg' + VilleData.message
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log("on load ville function");
    });
  };
  const registerSubmit = (e) => {
    e.preventDefault();
    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      pays: paysSelected.label,
      ville: villeSelected.label,
      phone: phoneCodeSelect + phoneNumber,
      password: password,
      namePar: namePar,
      emailPar: emailPar,
      phonePar: phoneCodeSelectPar + phoneNumberPar,
    };
    if (
      firstName &&
      lastName &&
      password === cpassword &&
      password.length >= 8
    ) {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios.post(`/api/register`, data).then((res) => {
          if (res.data.status === 200) {
            swal("Success", res.data.message, "success");
            localStorage.setItem("auth_token", res.data.token);
            localStorage.setItem("auth_username", res.data.username);
            localStorage.setItem("auth_email", res.data.email);
            localStorage.setItem("auth_photos", res.data.photos);
            //navigate
            window.location.reload();
            console.log(data);
          } else {
            setErrors(res.data.validation_errors);
            console.log(res.data.validation_errors);
          }
        });
      });
      console.log(data);
    } else {
      console.log(data);
      var error = {};
      if (password.length < 8) {
        error = { ...error, password: "Passwords do not match!" };
      }
      if (password !== cpassword) {
        error = {
          ...error,
          cpassword: "The password must be at least 8 characters.",
        };
      }
      setErrors(error);
      console.log(error);
    }
  };

  useEffect(() => {
    setAge(ageFn(dateOfBirth));
  }, [dateOfBirth]);

  useEffect(() => {
    loadPays();
    loadVille(149);
    phoneC();
  }, []);

  return (
    <Fragment>
      <form className="account-form row" onSubmit={registerSubmit}>
        <div className="form-group col-md-3 col-6">
          <label htmlFor="first_name">Prénom</label>
          <i className="text-danger">{errors.firstName}</i>
          <input
            type="text"
            id="first_name"
            name="first_name"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            required
          />
        </div>
        <div className="form-group col-md-3 col-6">
          <label htmlFor="last_name">Nom</label>
          <i className="text-danger">{errors.lastName}</i>
          <input
            type="text"
            name="last_name"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            required
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="last_name">Email</label>
          <i className="text-danger">{errors.email}</i>
          <input
            type="email"
            name="email"
            id="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="pays">Pays résidence à l'étranger</label>
          <Select
            onChange={(e) => setPaysSelected(e)}
            placeholder="Pays"
            inputId="pays"
            isLoading={pays.length === 0 ? true : false}
            defaultValue={paysSelected}
            options={pays}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
        </div>
        <div className=" form-group col-md-6">
          <label htmlFor="ville">Ville au Maroc</label>
          <Select
            onChange={(e) => setVilleSelected(e)}
            inputId="ville"
            isLoading={ville.length === 0 ? true : false}
            defaultValue={villeSelected}
            options={ville}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
        </div>

        <div className="form-group col-md-6">
          <label htmlFor="phoneNumber">Phone Number</label>
          <div className="input-group">
            <select
              className="form-select"
              aria-label="Default select example"
              value={phoneCodeSelect}
              onChange={(e) => setPhoneCodeSelect(e.target.value)}
              required
            >
              {phoneCode &&
                phoneCode?.map((items, i) => (
                  <option key={i} value={items.phone_code}>
                    {items.iso3 + " (+" + items.phone_code + ")"}
                  </option>
                ))}
            </select>
            <input
              id="phoneNumber"
              type="text"
              className="w-50 form-control"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="6666666666"
              required
            />
          </div>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="password">Password</label>
          <i className="text-danger">{errors.password}</i>
          <input
            id="password"
            type="password"
            name="password"
            autoFocus={false}
            autoComplete="off"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="cpassword">Confirm Password</label>
          <i className="text-danger">{errors.cpassword}</i>
          <input
            id="cpassword"
            type="password"
            name="cpassword"
            autoFocus={false}
            autoComplete="off"
            onChange={(e) => setCpassword(e.target.value)}
            value={cpassword}
            required
          />
        </div>
        <div className="form-group">
          <button type="submit" className="lab-btn">
            <span>Get Started Now</span>
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default Signup;
