import axios from "axios";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

const FilterGlobale = (props) => {
  const { lang, language } = useContext(LanguageContext);

  const [data, setData] = useState({
    cycle: [],
    niveau: [],
    matiere: [],
    ville: [],
    /* typeRencontre: [], */
  });
  const [error, setError] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    cycle: props.cycle ?? null,
    niveau: props.niveau ?? null,
    matiere: props.matiere ?? null,
    ville: props.ville ?? null,
    /* typeRencontre: props.typeRencontre ?? null, */
  });

  const fetchData = async (url) => {
    try {
      /* await axios.get("/sanctum/csrf-cookie"); */
      const response = await axios.get(url);
      return response.data.data.map((item) => {
        let nom = "";
        if (item.lang) {
          let langObj = JSON.parse(item.lang);
          nom = langObj[language];
        } else {
          nom = item.nom;
        }
        return {
          value: item.id,
          label: nom,
        };
      });
      setError(null);
    } catch (error) {
      console.log("Error fetching data:", error);
      throw new Error("Error fetching data. Please try again.");
    }
  };

  useEffect(() => {
    const fetchDataPromises = [
      fetchData("/api/cycle"),
      fetchData("/api/niveaux"),
      /* fetchData("/api/rencontre"), */
      fetchData("/api/ville/149"),
    ];

    Promise.all(fetchDataPromises)
      .then(([cycleData, niveauData, villeData]) => {
        /*  typeRencontreData, */
        setData((prevData) => ({
          ...prevData,
          cycle: [{ value: "all", label: lang("all") }, ...cycleData],
          niveau: niveauData,
          /* typeRencontre: typeRencontreData, */
          ville: villeData,
        }));
        setError(null);
      })
      .catch(() => setError("Error fetching data. Please try again."));
  }, []);

  const handleCycleChange = async (selectedCycle) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      cycle: selectedCycle,
      niveau: null,
      matiere: null,
    }));
    try {
      var niveauData;
      if (selectedCycle.value == "all") {
        niveauData = await fetchData(`/api/niveaux`);
      } else {
        niveauData = await fetchData(`/api/niveaux/${selectedCycle.value}`);
      }
      setData((prevData) => ({
        ...prevData,
        niveau: niveauData,
        matiere: [],
      }));
      setError(null);
    } catch (error) {
      console.log("Error fetching niveau data:", error);
      setError("Error fetching niveau data. Please try again.");
    }
  };

  const handleNiveauChange = async (selectedNiveau) => {
    if (!selectedFilters?.cycle?.value) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        cycle: { value: "all", label: "All" },
        niveau: selectedNiveau,
        matiere: null,
      }));
    } else {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        niveau: selectedNiveau,
        matiere: null,
      }));
    }
    try {
      const matieresData = await fetchData(
        `/api/matiere/${
          selectedNiveau.value + "/" + selectedFilters.cycle.value
        }`
      );
      setData((prevData) => ({
        ...prevData,
        matiere: matieresData,
      }));
      setError(null);
    } catch (error) {
      console.log("Error fetching matiere data:", error);
      setError("Error fetching matiere data. Please try again.");
    }
  };

  const handleOtherChange = (setter, value) => setter(value);

  const send = () => {
    /*typeRencontre,*/
    const { cycle, niveau, matiere, ville } = selectedFilters;
    if (cycle && niveau && matiere && ville) {
      /* && typeRencontre */
      props.onClick(selectedFilters);
      setError(null);
    } else {
      setError("Please select all options.");
    }
  };

  return (
    <div className="row align-items-center justify-content-center g-2">
      <div className="col-12 text-center">
        {error && <b className="text-warning">{error}</b>}
      </div>
      <div className="col-md-11">
        <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1 align-items-center justify-content-center g-1">
          <div className="col">
            <Select
              onChange={handleCycleChange}
              placeholder={lang("domain")}
              inputId="Domain"
              isLoading={data.cycle.length === 0}
              value={selectedFilters.cycle}
              options={data.cycle}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
          <div className="col">
            <Select
              onChange={handleNiveauChange}
              placeholder={lang("speciality")}
              inputId="Specialization"
              isLoading={!data.niveau}
              value={selectedFilters.niveau}
              options={data.niveau || []}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
          <div className="col">
            <Select
              onChange={(e) =>
                handleOtherChange(setSelectedFilters, {
                  ...selectedFilters,
                  matiere: e,
                })
              }
              placeholder={lang("service")}
              inputId="Service"
              isLoading={!data.matiere}
              value={selectedFilters.matiere}
              options={data.matiere || []}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
          <div className="col">
            <Select
              onChange={(e) =>
                handleOtherChange(setSelectedFilters, {
                  ...selectedFilters,
                  ville: e,
                })
              }
              placeholder={lang("city")}
              inputId="Ville"
              isLoading={!data.ville}
              value={selectedFilters.ville}
              options={data.ville || []}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
        </div>
      </div>
      <div className="col-md-1">
        <button
          type="button"
          onClick={send}
          className="lab-btn lab-btn-text px-4 py-1 rounded"
          style={{ backgroundColor: "#26c976" }}
        >
          <i className="icofont-search fw-bolde text-white fs-5"></i>
        </button>
      </div>
    </div>
  );
};

export default FilterGlobale;
