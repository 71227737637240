import FooterThree from "../../component/layout/footer-3";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import swal from "sweetalert";

const Settings = () => {
  const [image, setImage] = useState();
  const [user, setUser] = useState();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [pays, setPays] = useState([]);

  const [phoneCode, setPhoneCode] = useState(null);

  const [paysSelected, setPaysSelected] = useState();

  const [ville, setVille] = useState([]);
  const [villeSelected, setVilleSelected] = useState();

  const [errors, setErrors] = useState([]);

  const [phoneCodeSelect, setPhoneCodeSelect] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [password, setPassword] = useState({
    old: "",
    new: "",
    c: "",
    errors: "",
  });

  const infoUser = async () => {
    await axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .get("/api/getAllSetting")
        .then(function (res) {
          setUser(res.data);
          setFirstName(res.data.first_name);
          setLastName(res.data.last_name);
          console.log("user", res.data);
        })
        .catch((error) => {
          window.location.href = "/404";
        });
    });
  };

  const handleChange = (file) => {
    setImage(file[0]);
  };

  const editImage = (e) => {
    e.preventDefault();
    const fData = new FormData();
    fData.append("image", image);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post("/api/editImage", fData, {
          headers: {
            "Content-Type": `multipart/form-data;`,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setErrors();
            swal("Success", res.data.message, "success");
            localStorage.setItem("auth_photos", res.data.photos);
            infoUser();
          } else if (res.data.status === 401) {
            setErrors(res.data.message);
          } else {
            setErrors();
            swal("Success", res.data.message, "success");
            console.log(res);
          }
        })
        .catch((e) => {
          console.error("Failure", e);
        });
    });
  };
  const loadPays = async () => {
    await axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .get(`/api/pays`)
        .then((response) => {
          const paysArray = [];
          var paysData = response.data;
          if (paysData.success === true) {
            paysData.data.map((itmes) =>
              paysArray.push({
                value: itmes.id,
                label: itmes.nom,
              })
            );
            console.log('valid "pays" , message', paysData.message);
            setPays(paysArray);
          } else {
            console.log(
              'Error "Ville" file banner.jsx , msg' + paysData.message
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log("on load ville function");
    });
  };
  const phoneC = async () => {
    await axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .get(`/api/phoneCode`)
        .then((res) => {
          setPhoneCode(res.data.data);
          console.log(res.data);
        })
        .catch(function (err) {
          console.log(err);
        });
    });
  };
  const paysFunction = (e) => {
    setPaysSelected(e);
    loadVille(e.value);
  };
  const loadVille = (idPays) => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .get(`/api/ville/${idPays}`)
        .then((response) => {
          const villeArray = [];
          var VilleData = response.data;
          if (VilleData.success === true) {
            VilleData.data.map((itmes) =>
              villeArray.push({
                value: itmes.id,
                label: itmes.nom,
              })
            );
            console.log('valid "Ville" , message', VilleData.message);
            console.log(villeArray.length);
            setVille(villeArray);
          } else {
            console.log(
              'Error "Ville" file banner.jsx , msg' + VilleData.message
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log("on load ville function");
    });
  };

  const saveFullName = (e) => {
    e.preventDefault();
    const data = {
      firstName: firstName,
      lastName: lastName,
    };
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/saveFullName", data).then((res) => {
        if (res.data.status === 401) {
          setErrors(res.data.message);
        } else if (res.data.status === 200) {
          setErrors();
          swal("Success", res.data.message, "success");
          infoUser();
          document.getElementById("closeFullName").click();
        } else {
          setErrors();
          swal("Success", res.data.message, "success");
        }
        console.log(res.data);
      });
    });
  };
  const saveCountryCity = (e) => {
    e.preventDefault();
    const data = {
      paysSelected: paysSelected.label,
      villeSelected: villeSelected.label,
    };
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/saveCountryCity", data).then((res) => {
        if (res.data.status === 401) {
          setErrors(res.data.message);
        } else if (res.data.status === 200) {
          setErrors();
          swal("Success", res.data.message, "success");
          document.getElementById("closeCountryCity").click();
          infoUser();
        } else {
          setErrors();
          swal("Warning", res.data.message, "warning");
        }
        console.log(res.data);
      });
    });
  };

  const savePhoneNumber = (e) => {
    e.preventDefault();
    const data = {
      phoneNumber: phoneCodeSelect + phoneNumber,
    };
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/savePhoneNumber", data).then((res) => {
        if (res.data.status === 401) {
          setErrors(res.data.message);
        } else if (res.data.status === 200) {
          setErrors();
          swal("Success", res.data.message, "success");
          infoUser();
          document.getElementById("closePhoneNumber").click();
        } else {
          setErrors();
          swal("Success", res.data.message, "success");
        }
        console.log(res.data);
      });
    });
  };

  const editPassword = (e) => {
    e.preventDefault();
    const data = {
      passwordOld: password.old,
      passwordNew: password.new,
    };
    if (password.new.length >= 8) {
      if (password.new === password.c) {
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.post("/api/editPassword", data).then((res) => {
            if (res.data.status === 401) {
              setPassword({ ...password, errors: "401" });
            } else if (res.data.status === 200) {
              if (res.data.pass === true) {
                setPassword({ ...password, errors: "" });
                swal("Success", res.data.message, "success");
              } else {
                setPassword({
                  ...password,
                  errors: "These credentials do not match our records.",
                });
              }
            } else {
              setPassword({ ...password, errors: "404" });
              swal("Success", res.data.message, "success");
            }
            console.log(res.data);
          });
        });
      } else {
        setPassword({
          ...password,
          errors: "New password and confirme password do not match!",
        });
      }
    } else {
      setPassword({
        ...password,
        errors: "New password Very Weak! (Must be 8 or more chars)",
      });
    }
  };

  useEffect(() => {
    infoUser();
    loadPays();
    phoneC();
  }, []);

  return (
    <>
      <Header />
      <PageHeader title={"settings"} curPage={"settings"} />
      <div className="container my-2">
        <div className="card">
          <div className="card-body">
            <div className="card-text">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="text-center border border-2 border-secondary m-3 p-2">
                    <img
                      className="img-thumbnail rounded-circle mb-2"
                      src={localStorage.getItem("auth_photos")}
                      alt="photos profile"
                    />
                    <form method="post" onSubmit={editImage}>
                      <p className="text-danger fs-6">{errors?.image}</p>
                      <input
                        type="file"
                        name="image"
                        onChange={(e) => handleChange(e.target.files)}
                      />
                      <button className="btn btn-md btn-primary mt-3">
                        save
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row m-2 border-2 border-top  border-secondary py-3 align-items-center">
                    <div className="col-md-5 form-group ">
                      <p>First Name</p>
                      <p className="p-2 border border-2 bg-light">
                        {user?.first_name}
                      </p>
                    </div>
                    <div className="col-md-5 form-group ">
                      <p>Last Name</p>
                      <p className="p-2 border border-2 bg-light">
                        {user?.last_name}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#NameModal"
                      >
                        edit
                      </button>
                      {/* model edit full name */}
                      <div
                        className="modal fade"
                        id="NameModal"
                        tabIndex="-1"
                        aria-labelledby="NameModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="NameModalLabel">
                                edit full name
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <form onSubmit={saveFullName}>
                              <div className="modal-body">
                                <p className="fs-6 text-danger">
                                  {errors?.firstName}
                                </p>
                                <div className="input-group mb-3">
                                  <span
                                    className="input-group-text"
                                    id="firstName"
                                  >
                                    First Name
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                    aria-describedby="firstName"
                                  />
                                </div>
                                <p className="fs-6 text-danger">
                                  {errors?.lastName}
                                </p>
                                <div className="input-group mb-3">
                                  <span
                                    className="input-group-text"
                                    id="lastName"
                                  >
                                    Last Name
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                    aria-describedby="lastName"
                                  />
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  id="closeFullName"
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Save changes
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row m-2 border-2 border-top  border-secondary py-3 align-items-center">
                    <div className="col-md-5 form-group ">
                      <p>country</p>
                      <p className="p-2 border border-2 bg-light">
                        {user?.pays}
                      </p>
                    </div>
                    <div className="col-md-5 form-group ">
                      <p>city</p>
                      <p className="p-2 border border-2 bg-light">
                        {user?.city}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#paysCityModal"
                      >
                        edit
                      </button>
                      {/* model edit pays city */}
                      <div
                        className="modal fade"
                        id="paysCityModal"
                        tabIndex="-1"
                        aria-labelledby="paysCityModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="paysCityModalLabel"
                              >
                                edit full name
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <form onSubmit={saveCountryCity}>
                              <div className="m-2">
                                <span>country</span>
                                <p className="fs-6 text-danger">
                                  {errors?.paysSelected}
                                </p>
                                <Select
                                  onChange={(e) => paysFunction(e)}
                                  placeholder="Pays"
                                  inputId="Pays"
                                  isLoading={pays.length === 0 ? true : false}
                                  defaultValue={paysSelected}
                                  options={pays}
                                  menuPortalTarget={document.body}
                                  menuPosition={"fixed"}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </div>
                              <div className="m-2">
                                <span>city</span>
                                <p className="fs-6 text-danger">
                                  {errors?.villeSelected}
                                </p>
                                <Select
                                  onChange={(e) => setVilleSelected(e)}
                                  placeholder="ville"
                                  inputId="ville"
                                  isLoading={ville.length === 0 ? true : false}
                                  defaultValue={villeSelected}
                                  options={ville}
                                  menuPortalTarget={document.body}
                                  menuPosition={"fixed"}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </div>
                              <div className="modal-footer">
                                <button
                                  id="closeCountryCity"
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Save changes
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row m-2 border-2 border-top  border-secondary py-3 ">
                    <div className="col-md-4">
                      <p>phone number</p>
                    </div>
                    <div className="col-md-6 form-group">
                      <p className="p-1 px-2  border border-2 bg-light">
                        {user?.phone}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#phoneModal"
                      >
                        edit
                      </button>
                      {/* model edit phone number */}
                      <div
                        className="modal fade"
                        id="phoneModal"
                        tabIndex="-1"
                        aria-labelledby="phoneModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="phoneModalLabel">
                                edit full name
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <form onSubmit={savePhoneNumber}>
                              <p className="text-danger fs-6">
                                {errors?.phoneNumber}
                              </p>
                              <div className="input-group p-3">
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={(e) =>
                                    setPhoneCodeSelect(e.target.value)
                                  }
                                >
                                  {phoneCode &&
                                    phoneCode?.map((items, i) => (
                                      <option key={i} value={items.phone_code}>
                                        {items.iso3 +
                                          " (+" +
                                          items.phone_code +
                                          ")"}
                                      </option>
                                    ))}
                                </select>
                                <input
                                  type="text"
                                  className="w-50 form-control"
                                  value={phoneNumber}
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                />
                              </div>
                              <div className="modal-footer">
                                <button
                                  id="closePhoneNumber"
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Save changes
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row m-2 border-2 border-top border-bottom  border-secondary py-3 ">
                    <div className="col-sm-9">
                      <h6>Password </h6>
                    </div>
                    <div className="col-sm-3 text-end">
                      <button
                        className="btn btn-info collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#cpllapsePassword"
                        aria-expanded="false"
                        aria-controls="cpllapsePassword"
                      >
                        edit
                      </button>
                    </div>
                    <div className="collapse" id="cpllapsePassword">
                      <form onSubmit={editPassword}>
                        <div className="card card-body">
                          <p className="fs-6 text-danger">{password.errors}</p>
                          <div className="mt-3">
                            <label className="mb-1" htmlFor="oldPassword">
                              old password
                            </label>
                            <input
                              type="password"
                              value={password.old}
                              onChange={(e) =>
                                setPassword({
                                  ...password,
                                  old: e.target.value,
                                })
                              }
                              className="form-control"
                              name="oldPassword"
                            />
                          </div>
                          <div className="mt-3">
                            <label className="mb-1" htmlFor="newPassword">
                              new password
                            </label>
                            <input
                              type="password"
                              value={password.new}
                              onChange={(e) =>
                                setPassword({
                                  ...password,
                                  new: e.target.value,
                                })
                              }
                              className="form-control"
                              name="newPassword"
                            />
                            {/* <p className="fs-6 text-danger">{password.errors.new}</p> */}
                          </div>
                          <div className="mt-3">
                            <label className="mb-1" htmlFor="cPassword">
                              confirme password
                            </label>
                            <input
                              type="password"
                              value={password.c}
                              onChange={(e) =>
                                setPassword({ ...password, c: e.target.value })
                              }
                              className="form-control"
                              name="cPassword"
                            />
                            {/* <p className="fs-6 text-danger">{password.errors.c}</p> */}
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-md px-5"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterThree />
    </>
  );
};

export default Settings;
