import { Fragment, useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Footer from "../component/layout/footer-3";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Agenda from "../component/element/agenda";
import LoginSignup from "../component/element/loginSignup";
import { AuthContext } from "../context/AuthContext";
import StripeRedirect from "../component/element/stripeRedirect";
import axios from "axios";
import { LanguageContext } from "../context/LanguageContext";

const CartPage = () => {
  const { isAuthenticated, authUserEmail, authUserToken } =
    useContext(AuthContext);
  const { lang, dir } = useContext(LanguageContext);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [isChecked, setIsChecked] = useState(true);
  const [error, setError] = useState(null);
  const [programme, setProgramme] = useState([]);

  useEffect(() => {
    if (
      !location.state ||
      !location.state.cours ||
      !location.state.rencontre ||
      !location.state.dispo
    ) {
      console.log("redirect to : profile");
      navigate(`/profile/${params.id}`);
    }
  }, [location, navigate, params.id]);

  const [info, setInfo] = useState(() => {
    if (
      !location.state ||
      !location.state.cours ||
      !location.state.rencontre ||
      !location.state.dispo
    ) {
      return {
        cours: [],
        rencontre: [],
        dispo: [],
      };
    }
    return {
      cours: location.state.cours,
      rencontre: location.state.rencontre.map((val) => ({
        value: val.id,
        label: `${val.rencontre} - ${
          val.price + " " + location.state.currency
        }/ 45min`,
      })),
      dispo: location.state.dispo,
    };
  });

  const [timeSelect, setTimeSelect] = useState({
    fullDay: null,
    day: null,
    timeStart: null,
    timeEnd: null,
  });

  const [selected, setSelected] = useState({
    cours: null,
    rencontre: null,
  });
  /* dispo: null, */

  useEffect(() => {
    handleCoursChange("0");
  }, []);

  /* function */
  const handleRencontreChange = (e) => {
    setSelected((prevState) => ({
      ...prevState,
      rencontre: e,
    }));
  };

  const handleCoursChange = (courseIndex) => {
    coursProgramme(courseIndex);
    setSelected((prevState) => ({
      ...prevState,
      cours: courseIndex,
    }));
  };

  const coursProgramme = (courseIndex) => {
    const { cours } = info;
    const selectedCours = cours[courseIndex];

    if (selectedCours) {
      const updatedProgramme = selectedCours.programme.map((item) => ({
        id: item.id,
        nom: item.nom,
        relationId: item.relationId,
        checked: true,
      }));
      setProgramme(updatedProgramme);
    }
  };

  const selectAll = (e) => {
    setIsChecked(e.target.checked);

    const isChecked = e.target.checked;
    setProgramme((prevProgramme) =>
      prevProgramme.map((item) => ({
        ...item,
        checked: isChecked,
      }))
    );
  };

  const handleCheckboxChange = (index) => (e) => {
    setProgramme((prevProgramme) => {
      const updatedProgramme = [...prevProgramme];
      updatedProgramme[index].checked = !updatedProgramme[index].checked;
      return updatedProgramme;
    });
  };

  const [publishableKey, setPublishableKey] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [paiementStatus, setPaiementStatus] = useState(false);

  const paiement = () => {
    setPaiementStatus(true);
    const { day } = timeSelect || {};
    const isProgrammeChecked = programme?.some((prog) => prog.checked === true);
    if (isAuthenticated) {
      if (day && isProgrammeChecked && selected.rencontre) {
        try {
          const programmeChecked = programme
            .filter((prog) => prog.checked)
            .map((prog) => ({
              id: prog.id,
              relationId: prog.relationId,
            }));

          const message = document.getElementById("message").value;
          const data = {
            expertId: parseInt(params.id),
            auth_token: authUserToken,
            userEmail: authUserEmail,
            paiementMethode: "stripe",
            timeSelect: timeSelect,
            rencontre: parseInt(selected.rencontre),
            domaine: info.cours[selected.cours]["domaine"],
            matier_id: parseInt(info.cours[selected.cours]["matier_id"]),
            niveau_id: parseInt(info.cours[selected.cours]["niveau_id"]),
            option_id: parseInt(info.cours[selected.cours]["option_id"]),
            programme: programmeChecked,
            message: message,
          };
          console.log(data);
          axios.post(`/api/depositInsert`, data).then((res) => {
            console.log("reponse ::::>", res);
            if (res.data.success) {
              setPublishableKey(res.data.data.publishable_key);
              setSessionId(res.data.data.session_id);
            } else {
              const messages = res.data.message;
              setError(messages);
              console.log(messages);
            }
          });
        } catch (error) {
          console.error("Error:", error);
        }
        setError(null);
      } else {
        setError([lang("messageErreur1_cartPage")]);
        setPaiementStatus(false);
      }
    } else {
      setError([lang("messageErreur2_cartPage")]);
    }
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={lang("reservation")} curPage={"Réservation"} />
      <div className="shop-cart padding-tb" dir={dir}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {isAuthenticated ? (
                `hi ${localStorage.getItem("auth_username")}`
              ) : (
                <div className="card card-body mb-3">
                  <div
                    style={{
                      boxShadow: "0px 0px 10px 0px rgba(136, 136, 136, 0.1)",
                    }}
                  >
                    <LoginSignup />
                  </div>
                </div>
              )}
              <div className="card card-body">
                {/* select cours */}
                <div className="input-group">
                  <label className="input-group-text" htmlFor="cours">
                    {lang("service")}
                  </label>
                  <select
                    className="form-select"
                    id="cours"
                    onChange={(e) => handleCoursChange(e.target.value)}
                  >
                    {info.cours.map((cours, i) => (
                      <option key={i} value={i}>
                        {cours.matier} / {cours.niveau} / {cours.option}
                      </option>
                    ))}
                  </select>
                </div>
                {/* check programme */}
                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    onChange={selectAll}
                    checked={isChecked}
                    type="checkbox"
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {lang("selectAll")}
                  </label>
                </div>
                <div className="mt-2 row">
                  {programme.map((prog, i) => (
                    <div className="col-md-6" key={i}>
                      <div
                        className={`form-check ${
                          dir == "rtl" && "form-check-reverse"
                        }`}
                      >
                        <input
                          checked={prog.checked}
                          className="form-check-input"
                          type="checkbox"
                          value={prog.id}
                          id={`programme${i}`}
                          onChange={handleCheckboxChange(i)}
                        />
                        <label
                          className="form-check-label "
                          htmlFor={`programme${i}`}
                        >
                          {prog.nom}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* agenda */}
              <div className="my-4">
                <Agenda
                  dispo={info.dispo}
                  onclickTime={(value) => {
                    setTimeSelect(value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              {/* Séance time */}
              <div className="mb-2">
                <div className="card card-body">
                  <p className="fw-bold text-center m-0">
                    {lang("session")} :
                    {timeSelect.fullDay
                      ? timeSelect.fullDay +
                        " -> " +
                        timeSelect.timeStart +
                        " - " +
                        timeSelect.timeEnd
                      : " ... "}
                  </p>
                </div>
              </div>
              {/* rencontre */}
              <div className="card card-body">
                <label className="card-title" htmlFor="rencontre">
                  {lang("ChoisissezTypeDeRendezVous")} :
                </label>
                {info.rencontre && (
                  <>
                    {/* <Select
                      onChange={(e) => handleRencontreChange(e)}
                      placeholder="rencontre"
                      inputId="rencontre"
                      options={info.rencontre}
                    /> */}
                    {info.rencontre?.map((ren) => (
                      <div className="form-check" key={ren.value}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="typeRencontre"
                          id={"rencontre_" + ren.value}
                          value={ren.value}
                          onClick={(e) => handleRencontreChange(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"rencontre_" + ren.value}
                        >
                          {ren.label}
                        </label>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {/* paiement */}
              <div className="mt-4">
                <div className="card card-body">
                  <p className="card-title">
                    {lang("ChoisirMethodePaiement")} :
                  </p>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="paiement"
                      defaultChecked={true}
                    />
                    <label className="form-check-label" htmlFor="paiement">
                      {lang("carteBancaire")}
                      <br />
                      <img
                        style={{ maxWidth: "300px", width: "100%" }}
                        src="/assets/images/pyment/paiement.webp"
                        alt="carte bancaire"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <label htmlFor="message" className="mb-2">
                  {lang("message")}:
                </label>
                <textarea
                  id="message"
                  className="form-control border border-dark"
                  rows="3"
                ></textarea>
              </div>
              {isAuthenticated ? (
                <div className="mt-4 text-center">
                  {error && <p className="text-danger">{error}</p>}
                  <button className="lab-btn text-white" onClick={paiement}>
                    {lang("confirmerReservation")}
                  </button>
                </div>
              ) : (
                <div className="mt-4 text-center">
                  <button className="btn btn-secondary" disabled={true}>
                    {lang("confirmerReservation")}
                  </button>
                  <br />
                  <i>{lang("messageVeuillezConnecter")}</i>
                </div>
              )}
              {paiementStatus && (
                <div>
                  {publishableKey && sessionId ? (
                    <StripeRedirect
                      publishableKey={publishableKey}
                      sessionId={sessionId}
                    />
                  ) : (
                    <h6 className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {lang("loading")}...
                        </span>
                      </div>
                      {lang("loading")}...
                    </h6>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default CartPage;
