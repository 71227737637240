import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

const AboutTwo = () => {
  const { lang } = useContext(LanguageContext);

  const about_tow_list = Array.isArray(lang('about_tow_list')) ? lang('about_tow_list') : [] ; 
  return (
    <div id="comment_reserver" className="about-section style-2 pt-4">
      <div className="container">
        <div className="section-header text-center">
          <h2 className="title">{lang('about_tow_title')}</h2>
        </div>
        <div className="row justify-content-center row-cols-lg-3 row-cols-md-1 row-cols-1">
          {about_tow_list.map((val, i) => (
            <div className="col" key={i}>
              <div className="about-right">
                <div className="section-wrapper">
                  <ul className="lab-ul">
                    <li>
                      <div className="sr-left">
                        <img src={`${val.imgUrl}`} alt={`${val.title}`} />
                      </div>
                      <div className="sr-right ">
                        <h5>{val.title}</h5>
                        <p>{val.desc}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
