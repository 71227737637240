import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { LanguageContext } from "../../context/LanguageContext";

const getStartOfWeek = (date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

const addWeeks = (date, weeks) => {
  const result = new Date(date);
  result.setDate(result.getDate() + weeks * 7);
  return result;
};

const getWeekDays = (startOfWeek) => {
  return Array.from({ length: 7 }, (_, i) => {
    const day = new Date(startOfWeek);
    day.setDate(startOfWeek.getDate() + i);
    return day;
  });
};

const formatDate = (date, options) =>
  new Intl.DateTimeFormat("en-US", options).format(date);

const isPastDay = (date) => {
  const today = new Date();
  return (
    date < new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
};

const Agenda = ({ dispo, onclickTime }) => {
  const [currentWeek, setCurrentWeek] = useState(getStartOfWeek(new Date()));
  const [timeSelect, setTimeSelect] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [bookedTime, setBookedTime] = useState(null);

  const {lang} = useContext(LanguageContext)

  const changeWeek = (weeks) => {
    setSpinner(true);
    const newWeek = addWeeks(currentWeek, weeks);

    const updatedWeek =
      newWeek < getStartOfWeek(new Date())
        ? getStartOfWeek(new Date())
        : newWeek;

    setCurrentWeek(() => updatedWeek);

    axios
      .get(`/api/getBookings`, { params: { weekStart: updatedWeek.getTime() } })
      .then((res) => {
        console.log("Response Data:", res.data);
        setBookedTime(res.data.bookings);
      })
      .catch((err) => {
        console.error("Error fetching bookings:", err);
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const selectTime = (day, timeSlot) => {
    const selectedTime = {
      fullDay: formatDate(day, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      day: formatDate(day, { day: "2-digit", month: "2-digit" }),
      timeStart: timeSlot[0],
      timeEnd: timeSlot[1],
    };
    setTimeSelect(selectedTime);
    onclickTime(selectedTime);
  };

  const isBooked = (timeSlot) => {
    return bookedTime?.some(
      (booking) =>
        booking.day ===
          formatDate(timeSlot.day, { day: "2-digit", month: "2-digit" }) &&
        booking.timeStart === timeSlot[0]
    );
  };

  useEffect(() => {
    changeWeek(0);
  }, []);

  return (
    <div className="card card-body" dir="ltr">
      <h5 className="card-title">{lang("calendrier")}</h5>
      <div className="week-navigation">
        <button onClick={() => changeWeek(-1)}>{lang('semainePrecedente')}</button>
        <button onClick={() => changeWeek(1)}>{lang('semaineProchaine')}</button>
      </div>
      {spinner ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="text-center">{lang("loading")}...</p>
        </div>
      ) : (
        <ul className="day-list list-group">
          {getWeekDays(currentWeek).map((day, index) => {
            const formattedWeekday = formatDate(day, { weekday: "long" });
            const formattedDayMonth = formatDate(day, {
              day: "2-digit",
              month: "2-digit",
            });
            const dayDispos = dispo.find(
              (dispoDay) => dispoDay.jours === formattedWeekday
            );

            return (
              <li key={index} className="list-group-item day-item">
                <div className="row">
                  <div className="day-label col-3">
                    <span>{formattedWeekday}</span>
                    <span className="ms-1">{formattedDayMonth}</span>
                  </div>
                  <div className="time-slots col-9">
                    {dayDispos ? (
                      dayDispos.time.map((timeSlot, slotIndex) => (
                        <span
                          key={slotIndex}
                          className={`time-slot-span py-2 px-2 mx-1 
                            ${
                              timeSelect.day === formattedDayMonth &&
                              timeSelect.timeStart === timeSlot[0]
                                ? "selected-time-slot"
                                : ""
                            } 
                            ${isPastDay(day) ? "bg-secondary text-darck" : ""} 
                            ${
                              isBooked({ day, ...timeSlot })
                                ? "bg-danger text-white"
                                : ""
                            }
                          `}
                          onClick={() =>
                            !isPastDay(day) &&
                            !isBooked({ day, ...timeSlot }) &&
                            selectTime(day, timeSlot)
                          }
                        >
                          {timeSlot[0]}
                        </span>
                      ))
                    ) : (
                      <span>{lang("noAvailableTimes")}</span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Agenda;
