import { Fragment, useEffect, useState } from "react";
import FooterThree from "../../component/layout/footer-3";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import axios from "axios";
import AccordionCommandes from "./accordionCommandes";

const History = () => {
  const [commandes, setCommandes] = useState(null);

  useEffect(() => {
    axios
      .get("/api/getAllCommandes")
      .then((response) => {
        if (response.data.status === 200) {
          setCommandes(response.data.data);
          console.log("Commandes fetched successfully:", response.data.data);
        } else {
          console.error("Failed to fetch commandes:", response.data.message);
        }
      })
      .catch((error) => {
        window.location.href = "/404";
        console.error("An error occurred while fetching commandes:", error);
      });
  }, []);

  return (
    <Fragment>
      <Header />
      <PageHeader title="History" curPage="History" />
      <div className="container my-2">
        <div className="card">
          <div className="card-body">
            <div className="card-text">
              {commandes && <AccordionCommandes data={commandes} />}
            </div>
          </div>
        </div>
      </div>
      <FooterThree />
    </Fragment>
  );
};

export default History;
