import { useEffect, useState, Fragment, useContext } from "react";
import Login from "./login";
import Signup from "./signup";
import { LanguageContext } from "../../context/LanguageContext";

const LoginSignup = (param) => {
  const [formType, setFormType] = useState(param.type ? param.type : "type1");
  const { lang } = useContext(LanguageContext);

  useEffect(() => {
    setFormType(param.type);
  }, [param.type]);

  return (
    <Fragment>
      {formType === "type1" ? (
        <div className="px-3 py-2 bg-white m-auto">
          <div className="row mb-3">
            <h3 className="col-md-6 title">{lang("connexion")}</h3>
            <button className="col-md-6" onClick={() => setFormType("type2")}>
              {lang("inscription")}
            </button>
          </div>
          <Login />
        </div>
      ) : (
        <div className="px-3 py-2 bg-white m-auto">
          <div className="row mb-3">
            <h3 className="col-md-6 title"> {lang("inscription")} </h3>
            <button className="col-md-6" onClick={() => setFormType("type1")}>
              {lang("connexion")}
            </button>
          </div>
          <Signup />
        </div>
      )}
    </Fragment>
  );
};

export default LoginSignup;
