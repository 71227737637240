import { Link } from "react-router-dom";

const colorOrange = { color: "#f16126" };

const GradeIcons = ({ grades }) => (
  <div className="iconss">
    {grades.map((grade, i) => (
      <span key={i} className="px-1">
        <i
          style={{
            fontSize: `${grade.size}px`,
            color: grade.color,
          }}
          className={grade.icon}
          title={grade.name}
        ></i>
      </span>
    ))}
  </div>
);

const UserInfo = ({ user, matieres }) => (
  <div className="row">
    <div className="col-md-7">
      <div className="d-flex align-items-center">
        <h4 className="pe-2">
          {user.first_name} {user.last_name}.
        </h4>
        <GradeIcons grades={user.grades} />
      </div>
      <div className="row align-items-center">
        <div className="col-12">
          <span className="badge bg-success fs-6">{user.domaine}</span>
        </div>
        <div className="col-6">
          <i className="icofont-google-map  mt-1 me-2 fs-5 text-danger"></i>:
          <span className="ps-2">{user.city}</span>
        </div>
        {/*<div className="col-6">
          <i className="icofont-ui-rating" style={colorOrange}></i>
          <span className="fs-5">0 (0)</span>
        </div>
         <div className="col-6 col-md-9">
          <i className="icofont-hat-alt fs-5 text-dark"></i>
          <span className="ps-2">{matieres.label}</span>
        </div> */}
      </div>
    </div>
    <div className="col-md-5">
      <div className="row">
        {user.rencontre?.map((rencontre, key) => (
          <div key={key} className="col-12">
            <span className="fs-5 fw-bold">
              <i
                className={`${rencontre.icons} me-2`}
                style={{ color: "#26c976" }}
              ></i>
              {rencontre.price} {user.currency} /45 min
            </span>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const UserDescription = ({ user }) => (
  <div>
    <div className="pb-1 d-flex">
      <i className="icofont-globe-alt mt-1 me-2 fs-5 text-success"></i> :
      <div>
        {user.langueEn.map((langue, key) => (
          <span key={key} className="ms-1 badge bg-secondary">
            {langue}
          </span>
        ))}
      </div>
    </div>
    <h6 style={{ fontSize: "20px" }}>{user.titre}</h6>
    <div className="lh-sm">{user.presentation.substring(0, 150)}...</div>
  </div>
);

function CardProvider({ user, matieres }) {
  return (
    <Link to={`/profile/${user.idUser}`} style={{ width: "100%" }}>
      <div className="row">
        <div className="col-4 col-md-3 course-thumb text-center p-0">
          <img
            className="rounded-1"
            style={{ maxHeight: "200px", maxWidth: "200px" }}
            src={user.profile_photo_path}
            alt={`${user.first_name} ${user.last_name}`}
          />
        </div>
        <div className="col-8 col-md-9">
          <UserInfo user={user} matieres={matieres} />
          <div className="row d-none d-md-block">
            <div className="col-md-12">
              <UserDescription user={user} />
            </div>
          </div>
        </div>
        <div className="col-12 d-block d-md-none">
          <div className="row">
            <div className="col-md-12">
              <UserDescription user={user} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CardProvider;
