// AuthContext.js
import axios from "axios";
import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authUserEmail, setIsAuthUserEmail] = useState(null);
  const [authUserToken, setIsAuthUserToken] = useState(null);
  const [authUserName, setIsAuthUserName] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    if (token) {
      axios
        .get("/api/check-session", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setIsAuthenticated(true);
            setIsAuthUserEmail(localStorage.getItem("auth_email"));
            setIsAuthUserToken(token);
            setIsAuthUserName(localStorage.getItem("auth_username"));
          } else {
            handleLogout();
          }
        })
        .catch((error) => {
          console.log("Erreur de session:", error);
          handleLogout(); // Si erreur, on déconnecte l'utilisateur
        });
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("auth_email");
    localStorage.removeItem("auth_username");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        authUserEmail,
        authUserToken,
        authUserName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
