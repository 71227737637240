import axios from "axios";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import "swiper/css";

import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import CoursePage from "./page/course";
import CourseSingle from "./page/course-single";
import ForgetPass from "./page/forgetpass";
import HomeFour from "./page/home-4";
import History from "./page/dashboard/history";
import Settings from "./page/dashboard/settings";
import CartPage from "./page/cart-page";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import SuccessPayment from "./component/element/successPayment";
import LoginsignupPage from "./page/loginSignupPage";

// Set base URL and default headers
axios.defaults.baseURL = process.env.REACT_APP_URL_PROVIDER;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

// Ensure credentials are sent with requests (cookies for CSRF)
axios.defaults.withCredentials = true;

// Request interceptor to set Authorization header with JWT token
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("auth_token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function App() {
  useEffect(() => {
    try {
      axios.get("/sanctum/csrf-cookie");
      console.log("CSRF token fetched and session initialized.");
    } catch (error) {
      console.error("Failed to initialize session:", error);
    }
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter>
        <ScrollToTop />
        <AppRoute />
      </BrowserRouter>
    </AuthProvider>
  );
}

const AppRoute = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<HomeFour />} />
      <Route path="experts" element={<CoursePage />} />
      <Route path="profile/:id" element={<CourseSingle />} />
      <Route path="booking/:id" element={<CartPage />} />
      <Route path="success_paiement/:trx" element={<SuccessPayment />} />

      <Route
        path="login"
        element={
          isAuthenticated ? (
            <Navigate to="/" replace />
          ) : (
            <LoginsignupPage type="type1" />
          )
        }
      />
      <Route
        path="signup"
        element={
          isAuthenticated ? (
            <Navigate to="/" replace />
          ) : (
            <LoginsignupPage type="type2" />
          )
        }
      />

      {/* <Route
        path="signup22"
        element={
          isAuthenticated ? (
            <Navigate to="/" replace />
          ) : (
            <LoginsignupPage type="type2" />
          )
        }
      /> */}

      <Route
        path="history"
        element={isAuthenticated ? <History /> : <Navigate to="/" replace />}
      />
      <Route
        path="settings"
        element={isAuthenticated ? <Settings /> : <Navigate to="/" replace />}
      />

      <Route path="forgetpass" element={<ForgetPass />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default App;
