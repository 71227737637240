// src/components/SuccessPayment.js

import React from 'react';
import { useParams } from 'react-router-dom';

const SuccessPayment = () => {
  const { trx } = useParams();  

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Paiement Réussi !</h1>
      <p>Votre identifiant de transaction est : <strong>{trx}</strong></p>
      <p>Merci pour votre paiement. Vous recevrez une confirmation par email sous peu.</p>
      <a href="/" style={{ textDecoration: 'none', color: 'white' }}>
        <button style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          Retour à l'accueil
        </button>
      </a>
    </div>
  );
};

export default SuccessPayment;
