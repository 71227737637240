import { useState, Fragment } from "react";
import axios from "axios";
import swal from "sweetalert";

const Login = () => {
  const [email, setEmail] = useState(
    () => localStorage.getItem("auth_email") ?? undefined
  );
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [formType, setFormType] = useState("type1");

  const loginSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password,
    };
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/login", data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_email", email);
          localStorage.setItem("auth_username", res.data.username);
          localStorage.setItem("auth_photos", res.data.photos);
          swal("Success", res.data.message, "success");
          /* navigate('/') */
          window.location.reload();
        } else if (res.data.status === 401) {
          console.log("data", res.data.message);
          swal("Warning", res.data.message, "warning");
        } else {
          setErrors(res.data.validation_errors);
          console.log(res.data.validation_errors, "err");
        }
      });
    });
  };

  return (
    <Fragment>
      <form className="account-form row" onSubmit={loginSubmit}>
        <div className="form-group col-md-6">
          <label htmlFor="email">Email</label>
          <i className="text-danger">{errors?.email}</i>
          <input
            id="email"
            type="email"
            name="email"
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="password">Password</label>
          <i className="text-danger">{errors?.password}</i>
          <input
            type="password"
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
        </div>
        {/*
          <div className="form-group">
            <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                <div className="checkgroup">
                    <input type="checkbox" name="remember" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                </div> 
            </div>
          </div>
          */}
        <div className="form-group text-center">
          <button className="d-block lab-btn" type="submit">
            <span>connexion</span>
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default Login;
