import { Fragment } from "react";
import LoginSignup from "../component/element/loginSignup";
import FooterThree from "../component/layout/footer-3";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const LoginsignupPage = (param) => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Page"} curPage={"Login"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <LoginSignup type={param.type} />
        </div>
      </div>
      <FooterThree />
    </Fragment>
  );
};

export default LoginsignupPage;
