import axios from "axios";
import { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { LanguageContext } from "../../context/LanguageContext";

const FilterSecondary = (props) => {
  const { lang } = useContext(LanguageContext);
  const [selectedFilters, setSelectedFilters] = useState({
    genre: props.genre ?? null,
    price: props.price ?? 0,
    langueEn: props.langueEn ?? null,
    typeRencontre: props.typeRencontre ?? null,
  });

  const [data, setData] = useState({
    langue: [],
    genre: [],
    typeRencontre: [],
  });

  const fetchData = () => {
    axios
      .get(`/api/langueEnseignement`)
      .then((res) => {
        const langueData = res.data;
        if (langueData.status === true) {
          const LanguesArray = langueData.data.map(({ id, nom }) => ({
            value: id,
            label: nom,
          }));
          setData((prevData) => ({ ...prevData, langue: LanguesArray }));
          console.log('Valid "langue", message', langueData);
        } else {
          console.log('Error "langue", message', langueData);
        }
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  };

  const fetchDataRencontre = async () => {
    try {
      const response = await axios.get("/api/rencontre");
      const rencontre = response.data.data.map((item) => ({
        value: item.id,
        label: item.nom,
      }));
      setData((prevData) => ({
        ...prevData,
        typeRencontre: rencontre,
      }));
    } catch (error) {
      console.log("Error fetching data:", error);
      throw new Error("Error fetching data. Please try again.");
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataRencontre();
    setData((prevData) => ({
      ...prevData,
      genre: [
        { value: 0, label: lang("all") },
        { value: "M", label: "M" },
        { value: "F", label: "F" },
      ],
    }));
  }, []);

  const handleOtherChange = (setter, value) => setter(value);

  const send = () => {
    props.onClick(selectedFilters);
  };

  return (
    <div className="row align-items-end justify-content-center">
      <div className="col-12"></div>
      <div className="row-cols-1 align-items-center justify-content-center">
        <div className="col p-1">
          <label htmlFor="LangueEnseignement">
            {lang("langue_enseignement")}
          </label>
          <Select
            onChange={(e) =>
              handleOtherChange(setSelectedFilters, {
                ...selectedFilters,
                langueEn: e,
              })
            }
            placeholder={lang("all")}
            isMulti
            inputId="LangueEnseignement"
            isLoading={data.langue.length === 0}
            value={selectedFilters.langueEn}
            options={data.langue}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        </div>
        <div className="col p-1">
          <label htmlFor="genre">{lang("genre")}</label>
          <Select
            onChange={(e) =>
              handleOtherChange(setSelectedFilters, {
                ...selectedFilters,
                genre: e,
              })
            }
            placeholder=""
            inputId="genre"
            isLoading={data.genre.length === 0}
            value={selectedFilters.genre}
            options={data.genre}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        </div>
        <div className="col p-1">
          <label htmlFor="TypeRencontre">{lang("typeRencontre")}</label>
          <Select
            onChange={(e) =>
              handleOtherChange(setSelectedFilters, {
                ...selectedFilters,
                typeRencontre: e,
              })
            }
            placeholder={lang("all")}
            inputId="TypeRencontre"
            isLoading={!data.typeRencontre}
            value={selectedFilters.typeRencontre}
            options={data.typeRencontre || []}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            isMulti
          />
        </div>
        <div className="col p-1">
          <label htmlFor="price">{lang("prixMax")}</label>
          <input
            inputId="price"
            type="number"
            value={selectedFilters.price}
            onChange={(e) =>
              handleOtherChange(setSelectedFilters, {
                ...selectedFilters,
                price: !(isNaN(e.target.value) && e.target.value === "")
                  ? parseFloat(e.target.value)
                  : 0,
              })
            }
            min="0"
            max="1000"
            className="form-control"
            aria-describedby="priceMax"
          />
          <input
            type="range"
            value={selectedFilters.price}
            onChange={(e) =>
              handleOtherChange(setSelectedFilters, {
                ...selectedFilters,
                price: !(isNaN(e.target.value) && e.target.value === "")
                  ? parseFloat(e.target.value)
                  : 0,
              })
            }
            min="0"
            max="1000"
            className="form-range"
            id="priceMaxRange"
          />
        </div>
      </div>
      <div className="col-12">
        <button
          type="button"
          onClick={send}
          className="border border-primary border-2 px-4 py-1 bg-primary rounded"
          style={{ position: "initial" }}
        >
          <i className="icofont-search fw-bolder text-white fs-5"></i>
        </button>
      </div>
    </div>
  );
};

export default FilterSecondary;
