import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

const FeatureTwo = () => {
    const {lang} = useContext(LanguageContext);

    const feature_list = Array.isArray(lang('feature_list')) ? lang('feature_list') : [];
    return (
        <section id="features" className="feature-section style-2 padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle yellow-color">{lang('feature_sub_title')}</span>
                    <h2 className="title">{lang('feature_title')}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 justify-content-center">
                        {feature_list.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="feature-item">
                                    <div className="feature-inner">
                                        <div className="feature-icon">
                                            <i className={val.iconName}></i>
                                        </div>
                                        <div className="feature-content">
                                            <h5>{val.title}</h5>
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default FeatureTwo;