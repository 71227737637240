const AccordionCommandes = ({ data }) => (
  <div className="accordion accordion-flush mt-3" id={`accordion`}>
    <style>
      {`
        .accordion-button::after{
            margin-left: 0;
            margin-top: 10px;
        }
    `}
    </style>
    {data.map((commande, id) => (
      <div
        className="accordion-item mt-2 border border-dark border-1 rounded-4 rad p-2"
        key={id}
      >
        <h2 className="accordion-header mx-2 px-0" id={`head${id}`}>
          <div
            className="row justify-content-center accordion-button collapsed px-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#flush${id}`}
            aria-expanded="false"
            aria-controls={`flush${id}`}
          >
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <img
                  src={commande.photosProfile}
                  alt="Profile"
                  className="me-3"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "50%",
                    backgroundColor: "#f16126",
                    padding: "3px",
                  }}
                />
                <div>
                  <span className="text-uppercase">{commande.nom}</span>
                  <p className="mb-1">
                    <span>
                      Phone Number:
                      {commande.phone_number || (
                        <i className="ms-2 icofont-lock fs-5"></i>
                      )}
                    </span>
                  </p>
                  <p className="mb-1">
                    <span>
                      Email:
                      {commande.email || (
                        <i className="ms-2 icofont-lock fs-5"></i>
                      )}
                    </span>
                  </p>
                  <p className="mb-1">Created: {commande.created_at}</p>
                </div>
              </div>
            </div>

            <div className="col-md-3 text-center">
              <p className="mb-2">
                Matière: <span>{commande.matier}</span>
              </p>
              <p className="mb-2">
                Niveau: <span>{commande.niveau}</span>
              </p>
              <p className="mb-2">
                Option: <span>{commande.option}</span>
              </p>
            </div>

            <div className="col-md-3 text-center">
              <p className="mb-2">
                Booking day: <span>{commande.timeSelect?.fullDay}</span>
              </p>
              <p className="mb-2">
                Time:{" "}
                <span>
                  {commande.timeSelect?.timeStart +
                    " - " +
                    commande.timeSelect?.timeEnd}
                </span>
              </p>
            </div>

            <div className="col-md-2  text-center">
              <span className="badge text-bg-primary">{commande.status}</span>
            </div>
          </div>
        </h2>
        <div
          id={`flush${id}`}
          className="accordion-collapse collapse"
          aria-labelledby={`head${id}`}
          data-bs-parent={`#accordion`}
        >
          <div className="accordion-body">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <p>TRX : {commande.trx}</p>
                  <p>Country/City: {commande.pays + " / " + commande.city}</p>
                </div>
                <div>
                  <p>
                    Matière: <span>{commande.matier}</span>
                  </p>
                  <p>
                    Niveau: <span>{commande.niveau}</span>
                  </p>
                  <p>
                    Option: <span>{commande.option}</span>
                  </p>
                  <p className="mb-0">Programme:</p>
                  <ul>
                    {commande?.programme?.map((programme, i) => (
                      <li key={i}>{programme}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <p>Last Action: {commande.updated_at}</p>
                <p className="mb-1">Message:</p>
                <div className="px-3 py-2 border">{commande.messages}</div>
                <div className="mt-3">
                  <p>
                    Meeting Type:{commande.renconteName}
                    <i className={commande.renconteIcon}></i>
                  </p>
                  <p>
                    <span>Full Day: {commande.timeSelect?.fullDay}</span>
                    <br />
                    <span>Start Time: {commande.timeSelect?.timeStart}</span>
                    <br />
                    <span>End Time: {commande.timeSelect?.timeEnd}</span>
                  </p>
                  <p>
                    <span>amount: {commande.amount}</span>
                    <br />
                    <span>charge: {commande.charge}</span>
                    <br />
                    <span>final_amo: {commande.final_amo}</span>
                    <br />
                    <span>method payment: {commande.method_payment}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default AccordionCommandes;

