import FilterGlobale from "../element/filterGlobale";
//import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { LanguageContext } from "../../context/LanguageContext";
import { useContext } from "react";

const flagSlider = [
  {
    imgUrl: "assets/images/flag/01.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/02.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/03.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/04.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/05.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/06.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/07.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/08.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/09.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/10.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/11.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/12.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/13.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
  {
    imgUrl: "assets/images/flag/14.jpg",
    imgAlt: "flag rajibraj91 rajibraj",
  },
];

const BannerFour = () => {
  const { lang } = useContext(LanguageContext);

  const navigate = useNavigate();

  const openFilter = (selectedFilters) => {
    navigate("/experts", {
      state: {
        selectedFilters,
      },
    });
  };

  return (
    <div className="banner-section style-4">
      <div className="container">
        <div className="banner-content">
          <h3>{lang("banner_title")}</h3>
          <h4>{lang("banner_sub_title")}</h4>
          <div
            className="mt-5 mb-3 px-4 py-2 border border-1 border-dark"
            style={{ background: "#fff", borderRadius: "30px" }}
          >
            <FilterGlobale onClick={(value) => openFilter(value)} />
          </div>
          <p>{lang("banner_desc")}</p>
        </div>
        <div className="banner-bottom bg-white mt-3" dir="rtl">
          <div className="bannerflag-slider overflow-hidden">
            <Swiper
              spaceBetween={10}
              slidesPerView={6}
              loop={"true"}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                768: {
                  width: 768,
                  slidesPerView: 8,
                },
                1200: {
                  width: 1200,
                  slidesPerView: 13.2,
                },
              }}
            >
              {flagSlider.map((val, i) => (
                <SwiperSlide key={i}>
                  <div className="flag-item">
                    <div className="flag-thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerFour;
