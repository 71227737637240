
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { LanguageContext } from "../../context/LanguageContext";


const InstructorThree = () => {
    const {lang} = useContext(LanguageContext);

    const instructorBottomList =Array.isArray(lang('instructorBottomList')) ? lang('instructorBottomList') : [];
    return (
        <div id="nos_experts" className="instructor-section style-3 padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    {/* <span className="subtitle">{subTitle}</span> */}
                    <h2 className="title">{lang('instructorTitle')}</h2>
                </div>
                <div className="section-wrapper" dir="rtl">
                    <div className="instructor-bottom">
                        <div className="instructor-slider overflow-hidden">
                            <div className="instructor-navi instructor-slider-next"><i className="icofont-rounded-double-right"></i></div>
                            <div className="instructor-navi instructor-slider-prev"><i className="icofont-rounded-double-left"></i></div>
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={2}
                                loop={'true'}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    prevEl: '.instructor-slider-prev',
                                    nextEl: '.instructor-slider-next',
                                }}
                                modules={[Autoplay, Navigation]}
                                breakpoints={{
                                    768: {
                                        width: 768,
                                        slidesPerView: 3,
                                    },
                                    1200: {
                                        width: 1200,
                                        slidesPerView: 5.7,
                                    },
                                }}
                            >
                                {instructorBottomList.map((val, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="instructor-item">
                                            <div className="instructor-inner">
                                                <div className="instructor-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="instructor-content">
                                                    <Link to="/experts"><h5>{val.name}</h5></Link>
                                                    {/* <span className="d-block">{val.degi}</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default InstructorThree;